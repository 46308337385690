export const invoiceLogos = ['inv_logo']
export const hasSalesProgramSplit = true
export const HORUS_CK = "ck_v4srbs45nf851x2"
export const LABEL_CRM = "EBS"
export const LABEL_EVENT = "Event"
export const LABEL_QNOTE = "Quick note"
export const DEFAULT_VALUE_LONG_NOTE = ``
export const TERMS_CAPTION = 'I Accept EBS <a target="_blank" href="https://ebsummits.eu/static/files/EBS_conditions.pdf">General Terms of sales</a> and <a target="_blank" href="https://ebsummits.eu/static/files/privacy-policy.pdf">Privacy policy</a>'
export const SUCC_URL = "https://crm.ebsummit.eu/success"
export const STRIPE_KEY = "pk_live_51Pd68BKLPZiypEqb4NUAwx23O6KhVRVeCSOJ8UPGnRO01mhn8ZWlwuYcjsAq84kPsgAVldlA8ydddMPrj8BckkUa00LS4JSzTM"
export const REGISTRATION_FORM_CLASS = ""
export const GUEST_CAPTION = 'Person'