function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    var caseIns = false
    if(property[0] === "*") {
        caseIns = true
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var v1 = a[property]
        if (v1 === null)
            v1 = ""
        var v2 = b[property]
        if (v2 === null)
            v2 = ""
        if (caseIns)
        {
            v1 = v1.toUpperCase()
            v2 = v2.toUpperCase()
        }
        var result = (v1 < v2) ? -1 : (v1 > v2) ? 1 : 0;
        return result * sortOrder;
    }
}

export function dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

export function firstValue (dict) {
    var keys = Object.keys(dict)
    return (keys.length == 0) ? null : dict[keys[0]]
} 

export function firstSortedValue (dict, sortVar) {
    return oValues(dict).sort (dynamicSortMultiple(sortVar)) [0]
} 

export function oValues (dict) {
    if (!dict)
        return []
    return Object.keys(dict).map(function(key) {
        return dict[key];
    });
}

export function valueLabel (cObject, val, lab) {
    if (!cObject)
        return {value: -1, label: 'undefined'}
    return {value: cObject[val], label: cObject[lab]}
}

export function productValueLabel (pid, productConfig) {
    if (pid <= 0)
        return {"value":0, "label": '----'}
    var cp = productConfig.productsDic[pid]
    if (!cp)
        return {"value":0, "label": '----'}
    var rr = []
    if (cp.idEventEdition)
        rr.push (productConfig.editionsDic[cp.idEventEdition].shortName)
    if (cp.name)
        rr.push (cp.name)
    return {value: pid, label: rr.join (' - '), sublabel: cp.name}
}