import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import {redirectTo} from "../redux/actionsAuth"
import Button from "react-bootstrap/esm/Button";
import { dynamicSortMultiple, oValues } from "./_tools";

class TagsBrowser extends Component {
    state = {
        selectedTab: "External"
    }

    externalTags = []
    internalTags = []

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getTags'}))
    }

    render () {
        var contents = null
        var that = this
        this.externalTags = oValues(this.props.tagsConfig.externalDic).sort (dynamicSortMultiple('*label'))
        this.internalTags = oValues(this.props.tagsConfig.internalDic).sort (dynamicSortMultiple('*label'))
    
        
        if (this.state.selectedTab === 'External')
        {
            var lines = this.externalTags.map (function (p, ic) {
                return <tr key={"tag"+ic} role="button" onClick={(e)=>{ that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedTagFilter", "paramValue": [{value:p.value, label:p.label}] }); that.props.dispatch (redirectTo('/contacts'));  }}>
                    <td>{p.label}</td>
                    <td><span style={{backgroundColor:p.color}} className="p-2 rounded me-1 d-inline-block">{p.color}</span></td>
                    <td><Button onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": p.value})}}>Edit</Button></td>
                </tr>
            })
            contents = <div className="mt-3">
                <div className="mb-3">
                    {(that.props.profile.isMarketing || that.props.profile.isSales) && <Button className="float-end" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": 0})}}>+ Add tag</Button>}
                </div>
                <div style={{clear:"both"}}>
                    <Table responsive className="table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Color</th>
                                {that.props.isAdmin ? <th>Actions</th>:null}
                            </tr>
                        </thead>
                        <tbody>
                            {lines}
                        </tbody>
                    </Table>
                </div>
            </div>
        }
        if (this.state.selectedTab === 'Internal')
        {
            var lines = this.internalTags.map (function (p, ic) {
                return <tr key={"tag"+ic}>
                    <td>{p.label}</td>
                    <td><span style={{backgroundColor:p.color}} className="p-2 rounded me-1 d-inline-block">{p.color}</span></td>
                    {(that.props.isAdmin) && <td><Button onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": p.value})}}>Edit</Button></td>}
                </tr>
            })
            contents = <div className="mt-3">
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Color</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        return <div className="tagssBrowser">
            <Nav variant="tabs" justify
                activeKey={this.state.selectedTab}
                onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                <Nav.Item>
                    <Nav.Link eventKey="External">External</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Internal">Internal</Nav.Link>
                </Nav.Item>
            </Nav>
  
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        tagsConfig: state.data.tagsConfig,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(TagsBrowser)