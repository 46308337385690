import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, ON_LOAD_EVENT } from '../redux/actionsAPI';
import {LABEL_EVENT, LABEL_QNOTE} from '../econf/cnf'
import { productValueLabel } from './_tools';

class EventBrowseModal extends Component {
    state =  Object.assign({ selectedTab: "Editions" }) 

    componentDidMount() {
        if (this.props.interface.browsingEventId > 0)
        {
            this.props.dispatch ({type: ON_LOAD_EVENT, openEvent: {}})
            this.props.dispatch (apiRequest({'endpoint': 'getEvent', 'eventId': this.props.interface.browsingEventId}))    
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    render () {
        var that = this
        var contents = null
        
        if (this.state.selectedTab === 'Editions')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.idEditions)
                lines = that.props.openEvent.idEditions.map (function (pID, ic) {
                    var p = that.props.productConfig.editionsDic[pID]
                    return <tr key={"edition"+ic}  role="button" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEditionId", "paramValue": p.id})}}>
                        <td>{that.props.openEvent.name}</td>
                        <td>{p.subname}</td>
                        <td>{p.dateStart} {p.dateEnd ? ("- " + p.dateEnd):""}</td>
                        {that.props.isAdmin ? <td><Button onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": p.id})}}>Edit</Button></td>:null}
                    </tr>
                })
            contents = <div className="mt-3">
                <h3>Editions</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>{LABEL_EVENT}</th>
                            <th>Edition</th>
                            <th>Dates</th>
                            {that.props.isAdmin ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Sales')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.idSales)
                lines = that.props.openEvent.idSales.map (function (pID, ic) {
                    var p = that.props.sales[pID]
                    if (!p)
                        return null
                    
                    var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {}
                    return <tr key={"sale"+ic} role="button" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                                <td></td>
                                <td>{p.transactionDate}</td>
                                <td>{p.id}</td>
                                <td>{p.products.map (function (pp, ic) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) } {p.oneTimeProduct ? p.oneTimeProduct : ""}</td>
                                <td>{p.agreedPrice}</td>
                                <td>{p.quantity}</td>
                                <td>{pContact.fullName}</td>
                                <td>{(p.idCompany > 0) ? that.props.contactConfig.companiesDic[p.idCompany].name : null}</td>
                                <td>{p.note}</td>
                                <td>{(p.idBy > 0) ? that.props.users[p.idBy].fullName : p.historicBy }</td>
                                <td>{p.invoiceRef}</td>
                                <td>{(p.invoicePaid || p.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:(p.willCashPaid ? <i className={"fs-4 bi-hourglass"}></i>:null) }</td>
                                <td>{p.invoiceChecked ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                            </tr>
                })


            contents = <div className="mt-3">
                <h3>Sales</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>ID</th>
                            <th>Products</th>
                            <th>HTVA</th>
                            <th>QTE</th>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>{LABEL_QNOTE}</th>
                            <th>By</th>
                            <th>Invoice</th>
                            <th>Paid</th>
                            <th>CHK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Tasks')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.idTasks)
                lines = that.props.openEvent.idTasks.map (function (pID, ic) {
                    var p = that.props.tasks [pID]
                    if (!p)
                        return null
                    
                    var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : { idCompany: 0}
                    
                    return <tr className={p.hotState ? "task hot": "task"}  key={"task"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                        <td>{pContact.label}</td>
                        <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].name : null}</td>
                        <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""} </td>
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.proposalAmount} {p.proposalDate}</td>}
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                        <td>{p.taskStep.label}</td>
                        <td>{p.nextActionDate}</td>
                        <td>{p.updateDate}</td>
                        <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                    </tr>
                })


            contents = <div className="mt-3">
                <h3>Tasks</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                            <th>Responsible</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
        
        return <Modal size={'xl'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEventId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{LABEL_EVENT} details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Nav variant="tabs" justify
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                        <Nav.Item>
                            <Nav.Link eventKey="Editions">Editions</Nav.Link>
                        </Nav.Item>
                        {this.props.openEvent && this.props.openEvent.tasks && this.props.openEvent.tasks.length > 0 ? <Nav.Item>
                            <Nav.Link eventKey="Tasks">Open tasks</Nav.Link>
                        </Nav.Item>:null}
                        {this.props.openEvent && this.props.openEvent.idSales && this.props.openEvent.idSales.length > 0 ? <Nav.Item>
                            <Nav.Link eventKey="Sales">Sales</Nav.Link>
                        </Nav.Item>:null}
                    </Nav>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEventId", "paramValue": null})}}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        openEvent: state.data.openEvent,
        tasks: state.data.tasks,
        sales: state.data.sales,
        profile: state.data.profile,
        users: state.data.users,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig
    }
}

export default connect(mapStateToProps)(EventBrowseModal)