import React, { Component } from 'react';
import { connect } from 'react-redux'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CheckoutForm from "./CheckoutForm";

import {STRIPE_KEY, REGISTRATION_FORM_CLASS, TERMS_CAPTION, GUEST_CAPTION} from "../econf/cnf"

import {
    publicApiRequest,
    ON_LOAD_EDITION
} from '../redux/actionsAPI'

import {
    setNotification
} from '../redux/actions'
import { redirectTo } from '../redux/actionsAuth';

class EventRegistration extends Component
{
    md5 = require('md5');
    textFields = [["companyName", "Company / Organisation"]]
    personTextFields = [["firstName", "First Name"],["lastName", "Last Name"], ["email", "email"], ["jobTitle", "Job Title"]]
    billingTextFields = [["billingAddr", "Street & Number"], ["city", "City"], ["zipCode", "Postal code"], ['country', "Country"], ['billingVAT', "VAT Number"]]
    shippingTextFields = [["shippingAddr", "Street & Number"], ["shippingCity", "City"], ["shippingZipCode", "Postal code"], ['shippingCountry', "Country"]]

    state = Object.assign({
        purchasePassed: null,
        regCode: '',
        regOption: undefined,
        termsAccepted: false,
        editionId: 0,
        vipMode: false,
        askForCountry: false,
        numberOfPeopleInvolved: 1,
        onlyPaid: false,
        askForDOB: false,
        splitCompanies: false,
        payType: 'card',
        buttonStyle: {},
        forceBeVat: false,
        withShipping: false
    }, ...this.textFields.map ((el) => ({ [el[0]]:"" })), ...this.personTextFields.map ((el) => ({ [el[0]]:"" })), ...this.billingTextFields.map ((el) => ({ [el[0]]:"" })), ...this.shippingTextFields.map ((el) => ({ [el[0]]:"" }))) 

    stripePromise = null



    
    getJsonFromUrl(url) {
        var query = url.substr(1);
        var result = {};
        query.split("&").forEach(function(part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    componentDidMount() {
        var params = this.getJsonFromUrl (this.props.urlParams)
        if ((params.buttonColor) && (params.buttonColor.length === 6))
            this.setState ({buttonStyle:{backgroundColor: "#" + params.buttonColor, borderColor: "#" + params.buttonColor}})
        if (params.paidOnly)
            this.setState ({purchasePassed:"paid", regOption:undefined, 'onlyPaid': true})
        if (params.splitCompanies)
            this.setState ({splitCompanies:true})
        if (params.withCountry && params.withCountry.length > 2)
            this.setState ({askForCountry: true})
        if (params.withDOB && params.withDOB.length > 1)
            this.setState ({askForDOB: true})
        if (params.forceBeVat && params.forceBeVat.length > 0)
            this.setState ({forceBeVat: true})
        if (params.withShipping && params.withShipping.length > 0)
            this.setState ({withShipping: true})
        if (params.regCode && params.regCode.length > 2)
            this.setState ({regCode: params.regCode, regOption: "code", vipMode: true, purchasePassed:"reg"})
        if (params.eventID)
        {
            this.setState ({editionId: parseInt(params.eventID)})
            this.props.dispatch ({type: ON_LOAD_EDITION, openEdition: {}})
            this.props.dispatch (publicApiRequest({'endpoint': 'getEventEditionForRegistration', 'editionId': parseInt(params.eventID)}))    
        }

        if (STRIPE_KEY)
            this.stripePromise = loadStripe(STRIPE_KEY);
    }

    componentDidUpdate () {
        var cdiv = document.getElementById('eventRegistrationForm')
        if (cdiv)
            window.parent.postMessage ({ 'newContentSize': cdiv.clientHeight}, "*")

        var that = this
        if (!that.state.regOption && that.props.openEdition && that.props.openEdition.registrationConfig && (that.props.openEdition.registrationConfig.stripeProductsDetails && (that.props.openEdition.registrationConfig.stripeProductsDetails.length === 1)))
        {
            var sp = that.props.openEdition.registrationConfig.stripeProductsDetails[0]
            var pID = 'regStripe' + sp['id']
            that.setState({regOption:pID, numberOfPeopleInvolved:sp['numberOfPeopleInvolved']})
        }
    }


    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
        if (e.target.name === 'regOption')
            this.setState ({numberOfPeopleInvolved: 1})
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    handleSubmit = e => {
        e.preventDefault();
    }

    render() {
        var that = this
        var cPersonTextFields = [...that.personTextFields]
        var cTextFields = [...that.textFields]
        if (that.state.splitCompanies)
        {
            cTextFields.shift()
            cPersonTextFields.push (that.textFields[0])
        }


        if (that.props.stripeRedirect)
        {
            const appearance = {
                theme: 'stripe',
              };

            const options = {
                clientSecret: that.props.stripeRedirect,
                appearance,
              };
              
            return (
                <div className="pForm">
                    <Elements options={options} stripe={that.stripePromise}>
                        <CheckoutForm />
                    </Elements>  
                </div>
              ); 
        }

        var personalFields = []
        for (const pCnt of Array(that.state.numberOfPeopleInvolved).keys()) {
            personalFields.push (...cPersonTextFields.map (function (fn, ic) {
                if ((!that.state[fn[0]+pCnt]) || that.state[fn[0]+pCnt].length < 2)
                    fieldsFilled = false
                return <div className="control" key={"ptf" + pCnt + '_' + ic}>
                    <label htmlFor={fn[0]+pCnt} className="form-label">{fn[1]} {(that.state.numberOfPeopleInvolved > 1) ? (pCnt === 0 ? "(Contact Person)": (" (" + GUEST_CAPTION + " " + (pCnt+1) + ")")):""} <span className='asterisk'>*</span></label>
                    <input
                        className="form-control"
                        name={fn[0]+pCnt}
                        onChange={that.handleInputChange}
                        value={that.state[fn[0] + pCnt]}
                        type="text"
                    />
                </div>
            }))
            if (that.state.askForDOB) {
                if (!that.state['dobt' + pCnt])
                    fieldsFilled = false
                personalFields.push (<div className="control" key={"dob" + pCnt}>
                    <label htmlFor={"dob"+pCnt} className="form-label">Date of birth {(that.state.numberOfPeopleInvolved > 1) ? (" (" + GUEST_CAPTION + " " + (pCnt+1) + ")"):""} <span className='asterisk'>*</span></label>
                    <DatePicker 
                                    selected={that.state['dob' + pCnt]} 
                                    dateFormat="dd-MM-yyyy"
                                    calendarStartDay={1}
                                    onChange={(date) => { that.setState ({['dob' + pCnt]:date, ['dobt' + pCnt]: date ? date.getTime():date}); }}/>
                </div>)
            }
        }
        

        var hasPaidOptions = that.props.openEdition && that.props.openEdition.registrationConfig && (that.props.openEdition.registrationConfig.stripeProductsDetails && (that.props.openEdition.registrationConfig.stripeProductsDetails.length > 0))
        var customSelector = that.props.openEdition?.registrationConfig?.customSelector
        var regIntro = (that.props.openEdition && that.props.openEdition.registrationConfig && that.props.openEdition.registrationConfig.registrationIntro) ? that.props.openEdition.registrationConfig.registrationIntro : null
        var fieldsFilled = true
        if (customSelector && (!that.state.customSelector))
            fieldsFilled = false
        var regCodeNotFilled = ((that.state.regOption === 'code') && (that.state.regCode.length < 2)) || ((that.state.regOption === 'specialAsk') && (that.props.openEdition.registrationConfig.specialOptionInputCaption) && (that.props.openEdition.registrationConfig.specialOptionInputCaption.length > 3) && (that.state.regCode.length < 2))
        return (
            (that.props.openEdition && that.props.openEdition.id) ?
                <div className={"eventRegistrationForm " + REGISTRATION_FORM_CLASS} id="eventRegistrationForm">
                    {regIntro ? <div className='regIntro' dangerouslySetInnerHTML={{__html: regIntro}}></div>:null}
                    {hasPaidOptions && (!that.state.onlyPaid) ? 
                        <div className='buttonsContainer'>
                            <Row>
                                <Col xs={6}>
                                    <button style={that.state.buttonStyle} className={"btn btn-primary ms-3 me-3 mb-3 " + ((that.state.purchasePassed === "reg") ? "unselected":"")} onClick={(e)=>{ that.setState ({purchasePassed:"paid", regOption:undefined})}}>{that.props.openEdition.registrationConfig.paidOptionCaption ? that.props.openEdition.registrationConfig.paidOptionCaption : "I want to purchase a ticket"}</button>
                                    {((!that.state.purchasePassed) && that.props.openEdition.registrationConfig.paidOptionSub) ? <div className='sub'>{that.props.openEdition.registrationConfig.paidOptionSub}</div>:null}
                                </Col>
                                <Col xs={6}>
                                    <button style={that.state.buttonStyle}  className={"btn btn-primary ms-3 me-3 mb-3 " + ((that.state.purchasePassed === "paid") ? "unselected":"")} onClick={(e)=>{ that.setState ({purchasePassed:"reg", regOption:undefined})}}>{that.props.openEdition.registrationConfig.freeOptionCaption ? that.props.openEdition.registrationConfig.freeOptionCaption : "I have a right for a free registration"}</button>
                                    {((!that.state.purchasePassed) && that.props.openEdition.registrationConfig.freeOptionSub) ? <div className='sub'>{that.props.openEdition.registrationConfig.freeOptionSub}</div>:null}
                                </Col>
                            </Row>
                        </div>:null
                    }
                    {(!hasPaidOptions) || (that.state.purchasePassed) ?
                        <div> 
                            <div>
                                <div className={"control radioSelect" + (that.state.vipMode ? " d-none":"")}>
                                        {(!hasPaidOptions) || that.state.purchasePassed === 'reg' ? 
                                            <div>
                                                {that.props.openEdition.registrationConfig.registrationCodes && (that.props.openEdition.registrationConfig.registrationCodes.length > 0) ? <div><input type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "code"} value="code"/><label className="form-label" htmlFor="code">I received an invitation code</label></div>:null}
                                                <div><input  type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "ask"} value="ask"/><label className="form-label" htmlFor="ask">{(that.props.openEdition.registrationConfig.askCaption) && (that.props.openEdition.registrationConfig.askCaption.length > 3) ? that.props.openEdition.registrationConfig.askCaption : "I work for the European Institutions or an NGO and would like to attend free of charge"}</label></div>
                                                {(that.props.openEdition.registrationConfig.specialOptionCaption && that.props.openEdition.registrationConfig.specialOptionCaption.length > 3) ? <div><input type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "specialAsk"} value="specialAsk"/><label className="form-label" htmlFor="specialAsk">{that.props.openEdition.registrationConfig.specialOptionCaption}</label></div>:null}
                                            </div>:
                                            <div>
                                                {hasPaidOptions ? that.props.openEdition.registrationConfig.stripeProductsDetails.map (function (sp, ic) {
                                                    var pID = 'regStripe' + sp['id']
                                                    return <div key={pID}><input  type="radio" name="regOption" onChange={(e)=>{that.setState({regOption:pID, numberOfPeopleInvolved:sp['numberOfPeopleInvolved']})}} checked={that.state.regOption === pID} value={pID}/><label className="form-label" htmlFor={pID}>{sp['publicName']} - {sp['price']} Eur excl.VAT</label></div>
                                                }):null}
                                            </div>}
                                </div>  

                                {hasPaidOptions && that.props.openEdition.registrationConfig.wireTransferEnabled ? <div className={"control radioSelect"}>
                                        <div><input type="radio" name="payType" onChange={(e)=>{that.setState({payType:"card"})}} checked={that.state.payType === 'card'} value={'card'}/><label className="form-label">Online payment with a card</label></div>
                                        <div><input type="radio" name="payType" onChange={(e)=>{that.setState({payType:"wire"})}} checked={that.state.payType === 'wire'} value={'wire'}/><label className="form-label">Wire transfer payment</label></div>
                                    </div>:null}  
                                {that.state.regOption === 'code' ? 
                                    <div className={"control" + (that.state.vipMode ? " d-none":"")}>
                                        <label htmlFor="regCode" className="form-label">Invitation code<span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="regCode"
                                            onChange={that.handleInputChange}
                                            value={that.state.regCode}
                                            type="text"
                                        />
                                    </div>:null}
                                {(that.state.regOption === 'specialAsk') && (that.props.openEdition.registrationConfig.specialOptionInputCaption) && (that.props.openEdition.registrationConfig.specialOptionInputCaption.length > 3) ? 
                                    <div className={"control"}>
                                        <label htmlFor="regCode" className="form-label">{that.props.openEdition.registrationConfig.specialOptionInputCaption}<span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="regCode"
                                            onChange={that.handleInputChange}
                                            value={that.state.regCode}
                                            type="text"
                                        />
                                    </div>:null}

                                {customSelector ? <div className={"control radioSelect"}>
                                    <div>
                                         <label htmlFor="csel" className="form-label">{customSelector.caption}<span className='asterisk'>*</span></label>
                                        {Object.entries(customSelector.options).map (function (val) {
                                            var k = val[0]
                                            var v = val[1]
                                            return <div key={"csel" + k}><input  type="radio" name="customSelector" onChange={(e)=>{that.setState({customSelector:k})}} checked={that.state.customSelector === k} value={k}/><label className="form-label" htmlFor={"csel" + k}>{v}</label></div>
                                        })}
                                    </div>
                                </div> : null}

                                {personalFields}
                                
                                
                                
                                
                                
                                {cTextFields.map (function (fn, ic) {
                                    if ((!that.state[fn[0]]) || that.state[fn[0]].length < 2)
                                        fieldsFilled = false
                                    return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]} <span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                        />
                                    </div>
                                })}
                                {that.state.askForCountry ? <div className="control">
                                        <label htmlFor="country" className="form-label">Country <span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="country"
                                            onChange={that.handleInputChange}
                                            value={that.state["country"]}
                                            type="text"
                                        />
                                    </div>:null}

                                {that.state.regOption && that.state.regOption.indexOf("regStripe") !== -1 ? 
                                    <div className='billing'>
                                        <div className="control">
                                            <label className="form-label">Billing address</label>
                                        </div>
                                        
                                        {this.billingTextFields.map (function (fn, ic) {
                                            if (((!that.state[fn[0]]) || that.state[fn[0]].length < 2) && (fn[0] !== 'billingVAT'))
                                                fieldsFilled = false
                                            return <div className="control" key={"btf" + ic}>
                                                <label htmlFor={fn[0]} className="form-label">{fn[1]} {(fn[0] !== 'billingVAT') ? <span className='asterisk'>*</span>:null}</label>
                                                <input
                                                    className="form-control"
                                                    name={fn[0]}
                                                    onChange={that.handleInputChange}
                                                    value={that.state[fn[0]]}
                                                    type="text"
                                                />
                                            </div>
                                        })}
                                    </div>:null}

                                {that.state.withShipping ? 
                                    <div className='billing'>
                                        <div className="control">
                                            <label className="form-label">Shipping address</label>
                                        </div>
                                        
                                        {this.shippingTextFields.map (function (fn, ic) {
                                            if (((!that.state[fn[0]]) || that.state[fn[0]].length < 2) && (fn[0] !== 'billingVAT'))
                                                fieldsFilled = false
                                            return <div className="control" key={"btf" + ic}>
                                                <label htmlFor={fn[0]} className="form-label">{fn[1]} {(fn[0] !== 'billingVAT') ? <span className='asterisk'>*</span>:null}</label>
                                                <input
                                                    className="form-control"
                                                    name={fn[0]}
                                                    onChange={that.handleInputChange}
                                                    value={that.state[fn[0]]}
                                                    type="text"
                                                />
                                            </div>
                                        })}
                                    </div>:null}

                                

                                <div className="control">
                                    <input
                                        className="form-check-input"
                                        name="termsAccepted"
                                        onChange={that.handleCBChange}
                                        checked={that.state["termsAccepted"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="termsAccepted" className="form-check-label ms-2" dangerouslySetInnerHTML={{__html: TERMS_CAPTION}}></label>
                                </div>
                                            
                                                    
                                <div className="control mt-1 buttonsContainer">
                                    <button style={that.state.buttonStyle}  className={"float-right btn btn-primary" + (this.state.termsAccepted && fieldsFilled && (!regCodeNotFilled) && (this.state.regOption) ? "" : " disabled")} disabled={!(this.state.termsAccepted && fieldsFilled && (!regCodeNotFilled) && this.state.regOption)} onClick={(e)=>{
                                        if (that.state.billingVAT && (that.state.forceBeVat) && (that.state.billingVAT.length > 0) && (that.state.billingVAT.substring(0,2).toLowerCase() !== 'be'))
                                        {
                                            that.props.dispatch(setNotification ("Error occured.", "Only Belgian VAT number is possible for online sales. For sales abroad, please contact us directly."))
                                            return
                                        }
                                        const mailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                                        for (const pCnt of Array(that.state.numberOfPeopleInvolved).keys())
                                            if ((!that.state['email' + pCnt]) || (!mailRegExp.test(that.state['email' + pCnt])))
                                            {
                                                that.props.dispatch(setNotification ("Error occured.", "Please check the email is correct"))
                                                return
                                            }
        
                                        if ((that.state.regOption === 'ask') || (that.state.regOption === 'pressRequest') || (that.state.regOption === 'specialAsk'))
                                            this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: {}}))       
                                        if (that.state.regOption === 'code')
                                        {
                                            if (that.props.openEdition.registrationConfig.registrationCodes.includes (that.md5(that.state.regCode))) {
                                                this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: {}}))    
                                            }
                                            else {
                                                that.props.dispatch(setNotification ("Error occured.", "Incorrect invitation code. Please check"))
                                            }
                                        }
                                        if (that.state.regOption.indexOf("regStripe") !== -1) 
                                            this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: {}}))       
                                        
                                    }}>Submit</button>
                                </div>
                            </div>
                        </div>:
                        null}
                </div> : <div id="eventRegistrationForm" className={"eventRegistrationForm " + REGISTRATION_FORM_CLASS}><h4>{that.props.inlineMessage}</h4></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        openEdition: state.data.openEdition,
        inlineMessage: state.interfaceParams.inlineMessage,
        stripeRedirect: state.interfaceParams.stripeRedirect
    }
}

export default connect(mapStateToProps)(EventRegistration)
