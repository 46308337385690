import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Select from 'react-select';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";
import Creatable from 'react-select/creatable';


import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel } from "./_tools";

class HTasksBrowser extends Component {
    state = {
        cFilter: "",
        companyFilter: "",
        prodFilter: "",
    }
    
    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    tasksList = []
    selOptionsDic = {}

    componentDidUpdate(prevProps) {
        if ( (this.props.profile && this.props.profile.fullName && (!this.props.profile.isSuperAdmin)) && (! (prevProps.profile && prevProps.profile.fullName)))
            this.setState ({ respFilter: {value: this.props.profile.id, label:this.props.profile.fullName }})
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getHistoricTasks'}))
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    render () {
        var contents = null
        var that = this
        var tasksList = this.props.historicTasks ? this.props.historicTasks : [] //.sort (dynamicSortMultiple('nextActionDateF'))
        
        var counter = 0
        var lines = tasksList.map (function (p, ic) {
            var pContact = (p.contact_id > 0) ? that.props.contactConfig.contactsDic[p.contact_id] : {fullName:'', idCompany:-1}
            
            if ((that.state.cFilter.length > 0) && ((p.idContact < 0) || (! pContact.fullName.toLowerCase().includes (that.state.cFilter.toLowerCase()) )))
                return null
            
            if ((that.state.companyFilter.length > 0) &&  ((pContact.idCompany < 0) || ((! that.props.contactConfig.companiesDic [pContact.idCompany].shortName.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ) && (! that.props.contactConfig.companiesDic [pContact.idCompany].name.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ))))
                return null

            if ((that.state.prodFilter.length > 0) && (!p.code_article?.toLowerCase().includes (that.state.prodFilter.toLowerCase())))
            {
                return null
            }
            
            counter += 1
            
        
            return <tr className={"task"} key={"task"+ic}>
                <td>{(pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany].shortName : null}</td>
                <td role="button" onClick={(e) => {that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": p.contact_id})}}>{pContact.fullName}</td>
                <td>{p.code_article}</td>
                <td>{p.date_edit}</td>
            </tr>
        })

        contents = <div className="mt-3">
            <h3>Tasks ({counter})</h3>
            <Table responsive className="table-hover tasksTable">
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>Products</th>
                        <th>Updated</th>
                    </tr>
                    <tr>
                        <td>
                            <input
                                className="form-control"
                                name="companyFilter"
                                onChange={that.handleInputChange}
                                value={that.state["companyFilter"]}
                                type="text"
                            />
                        </td>
                        <td>
                            <input
                                className="form-control"
                                name="cFilter"
                                onChange={that.handleInputChange}
                                value={that.state["cFilter"]}
                                type="text"
                            />
                        </td>
                        <td>
                            <input
                                className="form-control"
                                name="prodFilter"
                                onChange={that.handleInputChange}
                                value={that.state["prodFilter"]}
                                type="text"
                            />
                        </td>
                        
                    </tr>
                    
                </thead>
                <tbody>
                    {lines}
                </tbody>
            </Table>
        </div>
    
        return <div className="tasksBrowser">
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        historicTasks: state.data.historicTasks,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
    }
}

export default connect(mapStateToProps)(HTasksBrowser)